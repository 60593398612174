import { Project } from './project.model';

/**
 * An alternative endpoint for the Project model that retrieves:
 *  - Projects where the employee is the project owner.
 *  - Projects where the employee is a direct manager of someone logging time on the project.
 */
export class ProjectOptions extends Project {
    protected static _resource = 'timeTracking/projectOptions';
}
