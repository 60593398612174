import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { extractOverlapErrors } from '@app/modules/time-tracking/functions/extract-overlaps';
import { NotifyService } from '@app/services';

interface ErrorObject {
    code: number;
    detail: string;
}

@Injectable()
export class TimesheetOverlapErrorHandlingService {
    constructor(private notifyService: NotifyService) {}

    handleHttpError(error: HttpErrorResponse): { overlapErrors: string[] } {
        let overlapErrors: string[] = [];
        const errorResponse = error.error;

        // Try new format first (array of error objects)
        if (Array.isArray(errorResponse?.errors)) {
            overlapErrors = errorResponse.errors
                .filter((err: ErrorObject) => err.code === 400)
                .map((err: ErrorObject) => err.detail);
        }

        // If no overlaps found, try legacy format
        if (overlapErrors.length === 0) {
            overlapErrors = extractOverlapErrors(errorResponse);
        }

        // If still no overlap errors found, show generic error
        if (overlapErrors.length === 0) {
            this.notifyService.error(ErrorParser.parse(error));
        }

        return { overlapErrors };
    }
}
